<template lang="pug">
    div
        div.header(id="home1")
            div.container
                div.w3l_login
                    a(href="#" data-toggle="modal" data-target="#myModal88")
                        span.glyphicon.glyphicon-user(aria-hidden="true")
                div.w3l_logo
                    h1
                        a(href="index.html") Electronic Store
                            span Your stores. Your place.
                div.search
                    input.search_box(type="checkbox" id="search_box")
                    label.icon-search(for="search_box")
                        span.glyphicon.glyphicon-search(aria-hidden="true")
                    div.search_form
                        form(action="#" method="post")
                            input(type="text" name="Search" placeholder="Search...")
                            input(type="submit" value="Send")
                div.cart.cart.box_1
                    form.last(action="#" method="post")
                        input(type="hidden" name="cmd" value="_cart")
                        input(type="hidden" name="display" value="1")
                        button.w3view-cart(type="submit" name="submit" value="")
                            i.fa.fa-cart-arrow-down(aria-hidden="true")
        navigation-component
        div.banner
            div.container
                h3 Electronic Store,
                    span Special Offers
        div.banner-bottom
            div.container
                div.col-md-5.wthree_banner_bottom_left
                    div.video-img
                        a.play-icon.popup-with-zoom-anim(href="#small-dialog")
                            span.glyphicon.glyphicon-expand(aria-hidden="true")
                        div.mfp-hide(id="small-dialog")
                            iframe(src="https://www.youtube.com/embed/ZQa6GUVnbNM")
                div.col-md-7.wthree_banner_bottom_right
                    div.bs-example.bs-example-tabs(role="tabpanel" data-example-id="togglable-tabs")
                        ul.nav.nav-tabs(id="myTab" role="tablist")
                            li.active(role="presentation")
                                a(href="#home" id="home-tab" role="tab" data-toggle="tab" aria-controls="home") Mobiles
                            li(role="presentation")
                                a(href="#audio" role="tab" id="audio-tab" data-toggle="tab" aria-controls="audio") Audio
                        div.tab-content(id="myTabContent")
                            div.tab-pane.fade.active.in(role="tabpanel" id="home" aria-labelledby="home-tab")
                                div.agile_ecommerce_tabs
                                    div.col-md-4.agile_ecommerce_tab_left
                                        div.hs-wrapper
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/6.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/7.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            div.w3_hs_bottom
                                                ul
                                                    li
                                                        a(href="#" data-toggle="modal" data-target="#myModal")
                                                            span.glyphicon.glyphicon-eye-open(aria-hidden="true")
                                        h5
                                            a(href="single.html") Mobile Phone1
                                        div.simpleCart_shelfItem
                                            p
                                                span $380
                                                i.item_price $350
                                            form(action="#" method="post")
                                                input(type="hidden" name="cmd" value="_cart")
                                                input(type="hidden" name="add" value="1")
                                                input(type="hidden" name="w3ls_item" value="Mobile Phone1")
                                                input(type="hidden" name="amount" value="350.00")
                                                button.w3ls-cart(type="submit") Add to cart
                                    div.col-md-4.agile_ecommerce_tab_left
                                        div.hs-wrapper
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/6.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/7.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            div.w3_hs_bottom
                                                ul
                                                    li
                                                        a(href="#" data-toggle="modal" data-target="#myModal")
                                                            span.glyphicon.glyphicon-eye-open(aria-hidden="true")
                                        h5
                                            a(href="single.html") Mobile Phone1
                                        div.simpleCart_shelfItem
                                            p
                                                span $380
                                                i.item_price $350
                                            form(action="#" method="post")
                                                input(type="hidden" name="cmd" value="_cart")
                                                input(type="hidden" name="add" value="1")
                                                input(type="hidden" name="w3ls_item" value="Mobile Phone1")
                                                input(type="hidden" name="amount" value="350.00")
                                                button.w3ls-cart(type="submit") Add to cart
                                    div.col-md-4.agile_ecommerce_tab_left
                                        div.hs-wrapper
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/6.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/7.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            div.w3_hs_bottom
                                                ul
                                                    li
                                                        a(href="#" data-toggle="modal" data-target="#myModal")
                                                            span.glyphicon.glyphicon-eye-open(aria-hidden="true")
                                        h5
                                            a(href="single.html") Mobile Phone1
                                        div.simpleCart_shelfItem
                                            p
                                                span $380
                                                i.item_price $350
                                            form(action="#" method="post")
                                                input(type="hidden" name="cmd" value="_cart")
                                                input(type="hidden" name="add" value="1")
                                                input(type="hidden" name="w3ls_item" value="Mobile Phone1")
                                                input(type="hidden" name="amount" value="350.00")
                                                button.w3ls-cart(type="submit") Add to cart
                                    div.clearfix
                            div.tab-pane.fade(role="tabpanel" id="audio" aria-labelledby="audio-tab")
                                div.agile_ecommerce_tabs
                                    div.col-md-4.agile_ecommerce_tab_left
                                        div.hs-wrapper
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/6.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/7.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/4.jpg" alt=" ")
                                            img.img-responsive(src="@/assets/paypeshop/images/5.jpg" alt=" ")
                                            div.w3_hs_bottom
                                                ul
                                                    li
                                                        a(href="#" data-toggle="modal" data-target="#myModal")
                                                            span.glyphicon.glyphicon-eye-open(aria-hidden="true")
                                        h5
                                            a(href="single.html") Mobile Phone1
                                        div.simpleCart_shelfItem
                                            p
                                                span $380
                                                i.item_price $350
                                            form(action="#" method="post")
                                                input(type="hidden" name="cmd" value="_cart")
                                                input(type="hidden" name="add" value="1")
                                                input(type="hidden" name="w3ls_item" value="Mobile Phone1")
                                                input(type="hidden" name="amount" value="350.00")
                                                button.w3ls-cart(type="submit") Add to cart
                                    div.clearfix
                div.clearfix
        div.modal.video-modal.fade(id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal")
            div.modal-dialog(role="document")
                div.modal-content
                    div.modal-header
                        button.close(type="button" data-dismiss="modal" aria-label="Close")
                            span(aria-hidden="true") &times;
                    section
                        div.modal-body
                            div.col-md-5.modal_body_left
                                img.img-responsive(src="@/assets/paypeshop/images/3.jpg" alt=" ")
                            div.col-md-7.modal_body_right
                                h4 The Best Mobile Phone 3GB
                                p Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                div.rating
                                    div.rating-left
                                        img.img-responsive(src="@/assets/paypeshop/images/star-.png" alt=" ")
                                    div.rating-left
                                        img.img-responsive(src="@/assets/paypeshop/images/star-.png" alt=" ")
                                    div.clearfix
                                div.modal_body_right_cart.simpleCart_shelfItem
                                    p
                                        span $380
                                        i.item_price $350
                                    form(action="#" method="post")
                                        input(type="hidden" name="cmd" value="_cart")
                                        input(type="hidden" name="add" value="1")
                                        input(type="hidden" name="w3ls_item" value="Mobile Phone1")
                                        input(type="hidden" name="amount" value="350.00")
                                        button.w3ls-cart(type="submit") Add to cart
                                h5 Color
                                div.color-quality
                                    ul
                                        li
                                            a(href="#")
                                                span
                                        li
                                            a.brown(href="#")
                                                span
                                        li
                                            a.purple(href="#")
                                                span
                                        li
                                            a.gray(href="#")
                                                span
                            div.clearfix
        div.banner-bottom1
            div.agileinfo_banner_bottom1_grids
                div.col-md-7.agileinfo_banner_bottom1_grid_left
                    h3 Grand Opening Event With flat
                        span 20%
                            i Discount
                    a(href="products.html") Shop Now
                div.col-md-5.agileinfo_banner_bottom1_grid_right
                    h4 hot deal
                    div.timer_wrap
                        div(id="counter")
                div.clearfix
        div.special-deals
            div.container
                h2 Special Deals
                div.w3agile_special_deals_grids
                    div.col-md-7.w3agile_special_deals_grid_left
                        div.w3agile_special_deals_grid_left_grid
                            img.img-responsive(src="@/assets/paypeshop/images/21.jpg" alt=" ")
                            div.w3agile_special_deals_grid_left_grid_pos1
                                h5 30%
                                    span Off/-
                            div.w3agile_special_deals_grid_left_grid_pos
                                h4 We Offer
                                    span Best Products
                        div.wmuSlider.example1
                            div.wmuSliderWrapper
                                article(style="position: absolute; width: 100%; opacity: 0;")
                                    div.banner-wrap
                                        div.w3agile_special_deals_grid_left_grid1
                                            img.img-responsive(src="@/assets/paypeshop/images/t1.png" alt=" ")
                                            p Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                            h4 Laura
                                article(style="position: absolute; width: 100%; opacity: 0;")
                                    div.banner-wrap
                                        div.w3agile_special_deals_grid_left_grid1
                                            img.img-responsive(src="@/assets/paypeshop/images/t2.png" alt=" ")
                                            p Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                            h4 Michael
                    div.col-md-5.w3agile_special_deals_grid_right
                        img.img-responsive(src="@/assets/paypeshop/images/20.jpg" alt=" ")
                        div.w3agile_special_deals_grid_right_pos
                            h4 Women's
                                span Special
                            h5 save up
                                span to 
                                template 30%
                    div.clearfix
        div.new-products
            div.container
                h3 New Products
                div.agileinfo_new_products_grids
                    div.col-md-3.agileinfo_new_products_grid
                        div.agile_ecommerce_tab_left.agileinfo_new_products_grid1
                            div.hs-wrapper.hs-wrapper1
                                img.img-responsive(src="@/assets/paypeshop/images/25.jpg" alt=" ")
                                img.img-responsive(src="@/assets/paypeshop/images/23.jpg" alt=" ")
                                img.img-responsive(src="@/assets/paypeshop/images/24.jpg" alt=" ")
                                img.img-responsive(src="@/assets/paypeshop/images/22.jpg" alt=" ")
                                img.img-responsive(src="@/assets/paypeshop/images/26.jpg" alt=" ") 
                                div.w3_hs_bottom.w3_hs_bottom_sub
                                    ul
                                        li
                                            a(href="#" data-toggle="modal" data-target="#myModal")
                                                span.glyphicon.glyphicon-eye-open(aria-hidden="true")
                            h5
                                a(href="single.html") Laptops
                            div.simpleCart_shelfItem
                                p
                                    span $520
                                    i.item_price $500
                                form(action="#" method="post")
                                    input(type="hidden" name="cmd" value="_cart")
                                    input(type="hidden" name="add" value="1")
                                    input(type="hidden" name="w3ls_item" value="Red Laptop")
                                    input(type="hidden" name="amount" value="500.00")
                                    button.w3ls-cart(type="submit") Add to cart
                    div.clearfix
        div.top-brands
            div.container
                h3 Top Brands
                div.sliderfig
                    ul(id="flexiselDemo1")
                        li
                            img.img-responsive(src="@/assets/paypeshop/images/tb1.jpg" alt=" ")
                        li
                            img.img-responsive(src="@/assets/paypeshop/images/tb2.jpg" alt=" ")
                        li
                            img.img-responsive(src="@/assets/paypeshop/images/tb3.jpg" alt=" ")
                        li
                            img.img-responsive(src="@/assets/paypeshop/images/tb4.jpg" alt=" ")
                        li
                            img.img-responsive(src="@/assets/paypeshop/images/tb5.jpg" alt=" ")
        div.newsletter
            div.container
                div.col-md-6.w3agile_newsletter_left
                    h3 Newsletter
                    p Excepteur sint occaecat cupidatat non proident, sunt.
                div.col-md-6.w3agile_newsletter_right
                    form(action="#" method="post")
                        input(type="email" name="Email" placeholder="Email" required="")
                        input(type="submit" value="")
                div.clearfix
        div.footer
            div.container
                div.w3_footer_grids
                    div.col-md-3.w3_footer_grid
                        h3 Contact
                        p Duis aute irure dolor in reprehenderit in voluptate velit esse.
                        ul.address
                            li
                                i.glyphicon.glyphicon-map-marker(aria-hidden="true")
                                template 1234k Avenue, 4th block,
                                span New York City.
                            li
                                i.glyphicon.glyphicon-envelope(aria-hidden="true")
                                a(href="mailto:info@example.com") info@example.com
                            li
                                i.glyphicon.glyphicon-earphone(aria-hidden="true")
                                template +1234 567 567
                    div.col-md-3.w3_footer_grid
                        h3 Information
                        ul.info
                            li
                                a(href="about.html") About Us
                            li
                                a(href="mail.html") Contact Us
                            li
                                a(href="codes.html") Short Codes
                            li
                                a(href="faq.html") FAQ's
                            li
                                a(href="products.html") Special Products
                    
                    div.col-md-3.w3_footer_grid
                        h3 Profile
                        ul.info
                            li
                                a(href="index.html") Home
                            li
                                a(href="products.html") Today's Deals
                        h4 Follow Us
                        div.agileits_social_button
                            ul
                                li
                                    a.facebook(href="#")
                                li
                                    a.twitter(href="#")
                                li
                                    a.google(href="#")
                                li
                                    a.pinterest(href="#")
                    div.clearfix
            div.footer-copy
                div.footer-copy1
                    div.footer-copy-pos
                        a.scroll(href="#home1")
                            img.img-responsive(src="@/assets/paypeshop/images/arrow.png" alt=" ")
                div.container
                    p &copy; 2017 Electronic Store. All rights reserved.
</template>
<script>
import NavigationComponent from './components/partials/NavigationComponent'
export default {
    name: 'Home',
    components: {
        NavigationComponent
    },
    mounted() {
        const script = document.createElement('script')
        script.src = '../../../assets/paypeshop/js/script.js'
        document.body.appendChild(script)

        window.jQuery(document).ready(function($) {
            $(".scroll").click(function(event){		
                event.preventDefault();
                $('html,body').animate({scrollTop:$(this.hash).offset().top},1000);
            });

            $('.popup-with-zoom-anim').magnificPopup({
                type: 'inline',
                fixedContentPos: false,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-zoom-in'
            });

            $("#flexiselDemo1").flexisel({
                visibleItems: 4,
                animationSpeed: 1000,
                autoPlay: true,
                autoPlaySpeed: 3000,    		
                pauseOnHover: true,
                enableResponsiveBreakpoints: true,
                responsiveBreakpoints: { 
                    portrait: { 
                        changePoint:480,
                        visibleItems: 1
                    }, 
                    landscape: { 
                        changePoint:640,
                        visibleItems:2
                    },
                    tablet: { 
                        changePoint:768,
                        visibleItems: 3
                    }
                }
            });
            
            $('.example1').wmuSlider();

        });

    }
}
</script>