<template lang="pug">
    div.navigation
        div.container
            nav.navbar.navbar-default
                div.navbar-header.nav_2
                    button.navbar-toggle.collapsed.navbar-toggle1(type="button" data-toggle="collapse" data-target="#bs-megadropdown-tabs")
                        span.sr-only Toggle navigation
                        span.icon-bar
                        span.icon-bar
                        span.icon-bar
                div.collapse.navbar-collapse(id="bs-megadropdown-tabs")
                    ul.nav.navbar-nav
                        li
                            router-link.act(to="/") {{$t('links.home')}}
                        li.dropdown
                            a.dropdown-toggle(href="#" data-toggle="dropdown") {{$t('links.products')}} 
                                b.caret
                            ul.dropdown-menu.multi-column.columns-3
                                div.row
                                    div.col-sm-3(v-for="category in $store.getters.categories" :key="category.id")
                                        ul.multi-column-dropdown
                                            h6 {{category.nombre}}
                                            li(v-for="level2 in category.children" :key="level2.id")
                                                a(href="products.html") {{level2.nombre}}
                                                    span New
                                    div.col-sm-4
                                        div.w3ls_products_pos
                                            h4 30%
                                                i Off/-
                                            img.img-responsive(src="@/assets/paypeshop/images/1.jpg" alt=" ")
                                    div.clearfix
                        li
                            a(href="about.html") About Us 
                        li.w3pages
                            a.dropdown-toggle(href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false") Pages 
                                span.caret
                            ul.dropdown-menu
                                li
                                    a(href="icons.html") Web Icons
                                li
                                    a(href="codes.html") Short Codes
                        li
                            a(href="mail.html") Mail Us
</template>
<script>
export default {
    name: 'NavigationComponent',
    async mounted() {
        this.openLoading()
        await this.getCategories()
        window.Swal.close()
        this.$store.commit('setCategories', this.categories)
    }
}
</script>